/* ==== variables ===== */
$color: rgb(211, 216, 221);
$color-cta: #7104e0;
$color-cta-variant: lighten(rgba(113, 4, 224, 0.8), 50%);
$color-light: rgb(141, 147, 151);
$color-bg-light: lighten(rgb(151, 222, 255), 10%);
$color-bg: rgb(64, 77, 85);
$color-gradient: linear-gradient(
	rgba(136, 153, 159, 0.7),
	rgba(91, 113, 114, 0.3)
);
$color-gradient-light: linear-gradient(
	rgba(174, 201, 207, 0.5),
	rgba(106, 126, 131, 0.1)
);
$white: #fff;
$transition: all 400ms ease;
$container-width-lg: 75%;
$container-width-md: 86%;
$container-width-sm: 90%;
