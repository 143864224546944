@import '../../index.scss';

.links__socials {
	font-size: 8rem;
	display: flex;
	justify-content: center;
	gap: 4rem;
	padding: 6rem 0;
	a {
		&:hover {
			color: $color-cta;
		}
	}
}
