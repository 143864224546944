@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

* {
	margin: 0;
	padding: 0;
	text-decoration: none;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
}

html {
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	display: none;
}

body {
	font-family: 'Montserrat', sans-serif;
	color: $color;
	background-color: $color-bg;
	line-height: 1.7;
	font-weight: 400;
}

body:after {
	content: '';
	position: fixed;
	top: 0;
	height: 100vh;
	left: 0;
	right: 0;
	z-index: -1;
	background-image: radial-gradient(
		circle at top right,
		rgb(18, 17, 17) 70%,
		hsl(270, 100%, 50%),
		hsl(240, 100%, 50%),
		hsl(210, 100%, 50%)
	);
}

/* =========== general styles ============ */
.container {
	width: $container-width-lg;
	margin: 0 auto;
	height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 800;
}

h1 {
	font-size: 2.5rem;
}

// section {
// 	margin-top: 2rem;
// 	padding-top: 2rem;
// }

section > h2,
section > h5,
section > h1 {
	text-align: center;
	color: $color;
}

section > h2 {
	color: $color;
	margin-bottom: 3rem;
	font-size: 2rem;
}

section > h5 {
	font-size: 1rem;
}

main > h2,
main > h5,
main > h1 {
	text-align: center;
	color: $color;
}

main > h2 {
	color: $color;
	margin-bottom: 3rem;
	font-size: 2rem;
}

main > h5 {
	font-size: 1rem;
}

.text-light {
	color: $color-light;
}

a {
	color: $color;
	transition: $transition;
	&:hover {
		color: $color-light;
	}
}

.btn {
	width: max-content;
	display: inline-block;
	color: $color;
	padding: 0.75rem 1.2rem;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid $color;
	transition: $transition;
	&:hover {
		background: $white;
		color: $color-bg;
		border-color: transparent;
	}
}

.btn-primary {
	background: $color-cta;
	color: $color;
}

img {
	display: block;
	width: 100%;
	object-fit: cover;
}

.cube-face {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	background-color: rgba(255, 255, 255, 0.8);
	color: #333;
	font-size: 18px;
	text-align: center;
	text-decoration: none;
}

.gradient-text {
	background-color: #7604eb;
	background-image: linear-gradient(to right, #5301a5, #7604eb, #084dfe);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

.text-bold {
	font-weight: 800;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
	.container {
		width: $container-width-md;
	}

	body:after {
		background-size: cover;
	}

	// section {
	// 	margin-top: 6rem;
	// }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
	.container {
		width: $container-width-sm;
	}

	section > h2 {
		margin-bottom: 2rem;
	}
	main > h2 {
		margin-bottom: 2rem;
	}
}
