@import '../../index.scss';

.portfolio__container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10rem;
	padding: 20px 100px;
	h1 {
		margin-top: 0;
	}
}

.portfolio-item-wrapper {
	border-radius: 2.2rem;
	padding: 5px;
	background: linear-gradient(45deg, #5301a5, #7604eb, #084dfe);
}

.portfolio__item {
	overflow: hidden;
	border-radius: 2rem;
	transition: $transition;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.portfolio__item-image {
		border-radius: 1.5rem;
		display: flex;
		transition: $transition;
		img {
			align-self: center;
		}
	}
}

.portfolio__item-description {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: transparent;
	padding: 2rem 0;
	.text-container {
		max-width: 300px;
		h3 {
			margin: 0.1rem 0;
		}
		p {
			font-size: 0.7rem;
		}
	}
	.btn {
		color: transparent;
		border: 1px solid transparent;
	}
	&:hover {
		transition: $transition;
		background-color: rgba(0, 0, 0, 0.8);
		color: white;
		.btn {
			color: white;
			border: 1px solid white;
			&:hover {
				background-color: white;
				color: $color-bg;
			}
		}
	}
}

.portfolio__item h3 {
	margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
	display: flex;
	gap: 1rem;
	margin: 1rem 0;
}

.modal__close {
	position: relative;
	height: 100%;
	button {
		position: absolute;
		right: -20px;
		top: -30px;
		cursor: pointer;
		background-color: transparent;
		color: white;
		font-size: 2rem;
		transition: $transition;
		&:hover {
			color: #5301a5;
		}
	}
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
	.portfolio__container {
		grid-template-columns: 1fr;
		gap: 1.2rem;
	}
	.portfolio__item-image {
		height: 150px;
	}
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
	.portfolio__container {
		grid-template-columns: 1fr;
		gap: 1rem;
		padding: 0;
	}
	.portfolio__item-image {
		height: 50px;
	}
}
