@import '../../index.scss';

.skills__container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
	position: relative;
	h1 {
		margin-top: 0;
	}
}

.skill__item {
	position: absolute;
	transition: $transition;
	display: flex;
	width: max-content;
	h3 {
		line-height: 0;
		&:hover {
			cursor: pointer;
			color: $color-cta;
			text-shadow: 1px 1px black;
		}
	}
	.hint {
		position: absolute;
		background-color: white;
		left: 100%;
		color: $color-cta;
		font-size: 0;
		border: 1px solid $color-cta;
		border-radius: 0.5rem;
		padding: 0.1rem;
		height: 0;
		width: 0;
		opacity: 0;
		transition: opacity 500ms ease;
	}

	.active {
		font-size: 0.5rem;
		width: max-content;
		height: max-content;
		opacity: 1;
	}
}
