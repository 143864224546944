@import '../../index.scss';

.about__container {
	margin-top: 40px;
	display: grid;
	height: 100%;
	grid-template-columns: 30% 60%;
	gap: 5%;
	border-radius: 10px;
	padding: 20px;
	margin: 20px;
}

.header__container {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 30px;
}

// .about__me {
//   width: 90%;
//   // aspect-ratio: 1/1;
//   border-radius: 2rem;
//   // background: linear-gradient(
//   //   45deg,
//   //   transparent,
//   //   $color-bg-light,
//   //   transparent
//   // );
//   display: grid;
//   // place-items: center;
// }

.about__me-image {
	border-radius: 2rem;
	margin-right: 10px;
	width: 100%;
	overflow: hidden;
	box-shadow: 5px -10px 10px rgba(0, 0, 0, 0.2);
}

// .about__cards {
// 	display: grid;
// 	grid-template-columns: 1fr;
// 	gap: 1.5rem;
// }

// .about__card {
// 	background: $color-gradient;
// 	border: 1px solid transparent;
// 	border-radius: 1rem;
// 	padding: 2rem 2rem 1rem 2rem;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	text-align: center;
// 	transition: $transition;
// 	min-height: 430px;
// 	height: fit-content;
// 	transform-style: preserve-3d;
// 	-webkit-transform-style: preserve-3d;
// 	-webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0))
// 		translateY(var(--translate-y, 0));
// 	transform: perspective(1000px) rotateY(var(--rotate-y, 0))
// 		translateY(var(--translate-y, 0));
// 	&:hover {
// 		background: $color-gradient-light;
// 		border-color: $color-cta;
// 		cursor: pointer;
// 	}
// }

// .about__card .back {
// 	transform: rotateY(180deg);
// 	-webkit-transform: rotateY(180deg);
// 	padding: 0 1rem;
// }

// .back,
// .front {
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// 	width: 100%;
// 	height: 100%;
// 	position: absolute;
// 	backface-visibility: hidden;
// }

// .about__card.flip {
// 	--rotate-y: 180deg;
// }

// .about__icon {
// 	color: $color-cta;
// 	font-size: 2.5rem;
// 	margin-bottom: 1rem;
// }

// .about__card h5 {
// 	font-size: 1.5rem;
// }

// .about__card p {
// 	color: $color-light;
// }

// .about__content p {
// 	margin: 2rem 0;
// 	color: $color;
// 	font-size: 0.9rem;
// }

/* =============== CTA ============== */
.cta {
	margin-top: 2.5rem;
	display: flex;
	gap: 1.2rem;
	justify-content: center;
	.btn {
		background-color: $color-cta;
		&:hover {
			background-color: white;
		}
	}
}

/* =============== SOCIALS ============== */
.about__socials {
	display: flex;
	justify-content: center;
	font-size: 2.2rem;
	gap: 3rem;
	a {
		&:hover {
			color: $color-cta;
		}
	}
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
	.about__container {
		grid-template-columns: 1fr;
		gap: 0;
	}

	.about__me {
		width: 50%;
		margin: 1rem auto 2rem;
	}

	.about__content p {
		margin: 1rem 0 1.5rem;
	}
	.about__me-image {
		max-height: 300px;

		width: auto;
		margin: 0 auto;
	}
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
	.about__me {
		width: 65%;
		margin: 0 auto 3rem;
	}

	.about__cards {
		grid-template-columns: 1fr;
		gap: 0.5rem;
	}

	.about__content {
		text-align: center;
	}

	.about__content p {
		margin: 1.5rem 0;
	}
	.about__me-image {
		max-height: 200px;

		width: auto;
		margin: 0 auto;
	}
}
